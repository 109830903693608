import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Atma",cursive;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .team_inner_wrapper{
    margin:100px 0px;
    display:flex;
    padding:5px 18px;
    justify-content:space-around;
    flex-wrap:wrap

  }
  .card_wrap img{
    object-fit:cover
  }
  .card_wrap{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    width:280px;
    height:300px;
    margin:18px 0px;
    background:#111; border:1px solid #fff; padding:5px; border-radius:10px;
    box-shadow: 1px 1px 30px 0px #fff;
  }
`

export default GlobalStyle
