import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Pikacrew',
  description:
    'The Best AMM on BSC by user count! Earn PKR through yield farming ir stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PikaCrew), NFTs, and more, on a platform you can trust.',
  image: 'images/home/earn/Metafloki-coin@2x.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Pikacrew')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('Pikacrew')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('Pikacrew')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Pikacrew')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('Pikacrew')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('Pikacrew')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('Pikacrew')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('Pikacrew')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('Pikacrew')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('Pikacrew')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('Pikacrew')}`,
      }
    default:
      return null
  }
}
